
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rules } from 'async-validator';

import { useCreateSeo, useUpdateSeo, useSeo } from '@/composables/api';
import { PartialSeo } from '@/interfaces/Seo';
import useFormErrors from '@/utils/validate';
import { IntBoolean } from '@/services/api';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialSeo = {
  title: '',
  description: '',
  page: 'Home',
  tagId: 0
};

const rules: Rules = {
  title: [
    {
      required: true
    }
  ],
  description: [
    {
      required: true
    }
  ],
  page: [
    {
      required: true
    }
  ],
  tagId: [
    {
      required: true
    }
  ]
};

const pageOptions = [
  {
    label: 'Home',
    value: 'home'
  },
  {
    label: 'Video',
    value: 'video'
  },
  {
    label: 'Model',
    value: 'model'
  },
  {
    label: 'Studio',
    value: 'studio'
  },
  {
    label: 'Genre',
    value: 'genre'
  }
];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const seoId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();
    const { t } = useI18n();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreateSeo();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateSeo();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useSeo({ seoId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { seoId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.push({
                      name: 'list-seos'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.push({
                    name: 'list-seos'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      page,
      formValues,
      formRef,
      rules,
      IntBoolean,
      isCreatedLoading,
      isUpdatedLoading,
      pageOptions,
      formErrors,
      submitForm,
      t,
      bindFormItemError
    };
  }
});
